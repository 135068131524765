.react-datepicker__current-month {
  display: none;
}

.react-datepicker__header {
  background-color: #055EFF !important;
}

.react-datepicker__navigation--previous {
  display: none;
}

.react-datepicker__navigation--next {
  display: none;
}

.react-datepicker__month-select {
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #055EFF;
}

.react-datepicker__year-select {
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #055EFF;
}

.react-datepicker__day-name {
  color: white !important;
}